import type { Privilege as IPrivilege } from '@/lib/privilege'

export interface MenuLink {
  title: string
  hash?: string
  routerName?: string
  params?: {
    type: string
  }
  locale?: string
  require?: IPrivilege[]
  track: string
  mobileIcon?: string   // mobile nav icon
  onClick?: () => any
  condition?: () => boolean
}

export type MenuDivider = '---'
export type MenuItem = MenuLink | MenuDivider

export interface MenuStructure {
  title: string
  icon: string
  items: MenuItem[]
}

export function isMenuLink(item: any): item is MenuLink { return item?.title }
export function isMenuDivider(item: any): item is MenuDivider { return item === '---' }
export function trimDivider(menuItems: MenuItem[]): MenuItem[] {
  const trimmedItems = menuItems.slice()
  const firstDivider = menuItems.indexOf('---')
  const lastDivider = menuItems.lastIndexOf('---')
  if (menuItems.length === 0) return []
  else if (lastDivider === menuItems.length - 1) { trimmedItems.splice(lastDivider, 1); return trimDivider(trimmedItems) }
  else if (firstDivider === 0) { trimmedItems.splice(firstDivider, 1); return trimDivider(trimmedItems) }
  else return menuItems
}

// 依據需要的權限篩選選單項目
export function filterByPrivilege(menuItems: MenuItem[]) {
  const items = menuItems.filter(item => {
    if (isMenuDivider(item)) return true
    if (item.condition && !item.condition()) return false
    if (item.require) return usePrivilege(item.require)
    else return true
  })
  return trimDivider(items)
}
