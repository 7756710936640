import { MenuStructure, filterByPrivilege, isMenuLink } from './menu-utils'
export * from './menu-utils'

export const AdminMenu: MenuStructure[] = [
  {
    title: '月報管理',
    icon: 'art_track',
    items: [
      { title: '月報列表', routerName: 'admin-reports-browse', track: 'Side,reports-browse', require: [Privilege.FactSheetManage] },
      { title: '限定版管理', routerName: 'admin-reports-limited-time', track: 'Side,reports-limited', require: [Privilege.FactSheetLimitedManage] },
    ],
  }
]

export function findAnyAdminPath() {
  const page = AdminMenu.map(menu => menu.items).flat().filter(item => isMenuLink(item)).find(item => usePrivilege(item.require!))
  const localePath = useLocalePath()
  if (page) {
    return localePath({ name: page.routerName })
  }
  return
}